import debounce from "lodash.debounce";

const intersects = [];
const $$intersectTargets = [];

const handleScroll = debounce(() => {
  intersects.forEach(({ $intersect, center }, i) => {
    const isIntersecting = $$intersectTargets.some(($intersectTarget) => {
      const { top, bottom } = $intersectTarget.getBoundingClientRect();
      return center > top && center < bottom;
    });
    $intersect.classList[isIntersecting ? 'add' : 'remove']('is-intersecting')
  });

}, 50, { leading: true, maxWait: 50 });

export const attachIntersectsEvents = () => {
  const $$intersects = Array.from(document.querySelectorAll('[data-intersect]'));

  if (!$$intersects.length) return;

  // first, empty arrays
  intersects.splice(0, intersects.length);
  $$intersectTargets.splice(0, $$intersectTargets.length);

  Array.from(document.querySelectorAll('[data-intersect-target]'))
    .forEach(($intersectTarget) => $$intersectTargets.push($intersectTarget));

  $$intersects.forEach(($intersect) => {
    const { top, height } = $intersect.getBoundingClientRect();
    $intersect.classList.remove('is-intersecting');
    intersects.push({
      center: top + height / 2,
      $intersect
    });
  });

  window.addEventListener('scroll', handleScroll);
};

export const detachIntersectsEvents = () => {
  if (!intersects.length) return;

  window.removeEventListener('scroll', handleScroll);
};