export const validate = ($el) => {
  const hasValue = $el.type === 'checkbox' ? $el.checked : !!$el.value;
  $el.parentElement.classList[hasValue ? 'add' : 'remove']('has-value');
  $el.parentElement.classList[!$el.validity.valid ? 'add' : 'remove']('is-invalid');
};

export const initFormElement = ($el) => {
  $el.addEventListener('focus', () => $el.parentElement.classList.add('is-focused'))
  $el.addEventListener('blur', () => {
    $el.parentElement.classList.remove('is-focused')
    validate($el);
  })
  $el.addEventListener('keyup', () => validate($el))
  $el.addEventListener('change', () => validate($el))
};

export const handleFormSubmit = async (e, $form) => {
  e.preventDefault();
  const $$formElements = Array.from($form.elements);
  $form.classList.remove('has-failed');
  $$formElements.forEach(validate);
  const $firstInvalidElement = $$formElements.find(($el) => !$el.validity.valid);
  if ($firstInvalidElement) {
    $firstInvalidElement.focus();
  } else {
    const formData = $$formElements.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {});
    fetch($form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((res) => {
        if (!res.ok) {
          $form.classList.add('has-failed');
          throw Error(res.statusText);
        }
        return res.json();
      }).then((data) => {
        $form.classList.add('has-succeeded');
      })
      .catch((err) => {
        $form.classList.add('has-failed');
      });
  };
}

export const formClickHandler = (e) => {
  const $resultClose = e.target.closest('[data-result-close]');
  if (!$resultClose) return;

  const $form = e.target.closest('form');
  $form.classList.remove('has-succeeded');
  $form.classList.remove('has-failed');
}

export const initForm = ($form) => {
  Array.from($form.elements).forEach(initFormElement);
  $form.addEventListener('submit', (e) => handleFormSubmit(e, $form));
}

export const initForms = () => {
  Array.from(document.forms).forEach(initForm);
};