import { ScrollTrigger } from "gsap/ScrollTrigger";

export const fadeTrigger = ($el) => {
  ScrollTrigger.create({
    trigger: $el,
    start: "top 30%",
    end: "bottom 30%",
    onToggle: ({ isActive }) => {
      document.documentElement.style.setProperty('--bodyBackgroundColor', isActive ? '#fff' : '#e5dcd8')
    }
  });
};

export const initFadeTriggers = () => document.querySelectorAll('[data-fade-trigger]').forEach(fadeTrigger);