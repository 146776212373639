import { gsap } from "gsap";

export const revealBtn = ($el) => {
  gsap.to($el, {
    scale: 1,
    opacity: 1,
    duration: 0.8,
    ease: "back.out",
    clearProps: true,
    onComplete: () => $el.removeAttribute('data-reveal-btn'),
    scrollTrigger: {
      trigger: $el,
    },
  });
};

export const initRevealBtns = () => Array
  .from(document.querySelectorAll('[data-reveal-btn]'))
  .forEach(revealBtn);