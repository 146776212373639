export const openTogglerItem = ($togglerItem) => {
  const isOpen = $togglerItem.classList.contains('is-open');
  const $closestToggler = $togglerItem.closest('[data-toggler]');
  const $$siblingTogglerItems = Array.from($closestToggler.querySelectorAll('[data-toggler-item]'));

  $$siblingTogglerItems.forEach(($el) => $el.classList.remove('is-open'));
  $togglerItem.classList[isOpen ? 'remove' : 'add']('is-open');

  if (isOpen) {
    history.replaceState(null, null, ' ');
  } else {
    $togglerItem.classList.add('is-open');
    location.hash = `#${$togglerItem.dataset.togglerItem}`;
  }
}

export const togglerClickHandler = (e) => {
  const $togglerTrigger = e.target.closest('[data-toggler-trigger]')
  if (!$togglerTrigger) return;

  const $togglerItem = $togglerTrigger.closest('[data-toggler-item]');

  openTogglerItem($togglerItem);
}

export const initTogglers = () => {
  const $targetTogglerItem = document.querySelector(`[data-toggler-item="${location.hash.replace('#', '')}"]`);

  if ($targetTogglerItem) {
    openTogglerItem($targetTogglerItem);
    $targetTogglerItem.scrollIntoView(true);
  }
};