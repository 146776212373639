import { gsap } from "gsap";

export const revealImage = ($el) => {
  // const offset = (+$el.dataset.revealImage ?? 0) / 1000;
  const delay = (+$el.dataset.revealImage ?? 0) / 1000;

  gsap.to($el, {
    // height: '105%',
    delay,
    // y: 300,
    y: 0,
    // top: 0,
    opacity: 1,
    duration: 1.8,
    ease: "expo.out",
    onComplete: () => $el.removeAttribute('data-reveal-image'),
    scrollTrigger: {
      trigger: $el,
    },
  });
};

export const initRevealImages = () => Array
  .from(document.querySelectorAll('[data-reveal-image]'))
  .forEach(revealImage);