import { gsap } from "gsap";

export const initMarquee = ($el) => {
  gsap.to($el, {
    xPercent: +$el.dataset.marquee || 10,
    scrollTrigger: {
      trigger: $el,
      scrub: 1,
    },
  })
}

export const initMarquees = () => Array.from(document.querySelectorAll('[data-marquee]')).forEach(initMarquee);