import { gsap } from "gsap";

export const revealTexts = ($el) => {
  const delay = (+$el.dataset.revealText ?? 0) / 1000;

  gsap.to($el, {
    delay,
    y: 0,
    opacity: 1,
    duration: 1.8,
    ease: "expo.out",
    stagger: .3,
    onComplete: () => $el.removeAttribute('data-reveal-text'),
    scrollTrigger: {
      trigger: $el,
    },
  });
};

export const initRevealTexts = () => Array
  .from(document.querySelectorAll('[data-reveal-text]'))
  .forEach(revealTexts);