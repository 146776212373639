import noUiSlider from 'nouislider';

export const isInRange = (value, from, to) => value >= from && value <= to;

export const initListing = () => {
  const controls = {};

  const listingRows = Array.from(document.querySelectorAll('[data-listing-row]')).map(($listingRow) => {
    const {
      listingFloor,
      listingArea,
      listingRooms,
      listingType,
      listingStatus,
    } = $listingRow.dataset;

    return {
      floor: +listingFloor,
      area: +listingArea,
      rooms: +listingRooms,
      type: listingType,
      status: listingStatus,
      $el: $listingRow,
    };
  });

  function updateControls(id, value) {
    controls[id] = value;

    if (controls.floor == null) return;
    if (controls.area == null) return;
    if (controls.rooms == null) return;
    if (controls.vacant == null) return;
    if (controls.commercial == null) return;

    listingRows.forEach(function(row) {
      const isVisible =
        isInRange(row.floor, controls.floor.from, controls.floor.to) &&
        isInRange(row.area, controls.area.from, controls.area.to) &&
        isInRange(row.rooms, controls.rooms.from, controls.rooms.to) &&
        // check if row is not commecrial. otherwise check if commercial control is set to true 
        (row.type !== "commercial" || controls.commercial) &&
        // check if show only vacant control is off. otherwise check if row status is vacant  
        (!controls.vacant || row.status === "vacant");

      row.$el.classList[isVisible ? 'remove' : 'add']('is-hidden');
    })
  };

  document.querySelectorAll('[data-range-control]').forEach(function($rangeControl) {
    const {
      rangeControl,
      rangeEnd,
      rangeStart,
      rangeMin,
      rangeMax,
      rangeStep,
    } = $rangeControl.dataset;

    updateControls(rangeControl, {
      from: +rangeStart,
      to: +rangeEnd
    });

    // if noUi slider is already initialized, destory it 
    const $existingNoUiBase = $rangeControl.querySelector('.noUi-base');
    if ($existingNoUiBase) {
      $existingNoUiBase.remove();
    }

    noUiSlider.create($rangeControl, {
      connect: true,
      start: [+rangeStart, +rangeEnd],
      range: {
        'min': [+rangeMin],
        'max': [+rangeMax]
      },
      step: +rangeStep
    }).on('update', function (values, handle, [from, to]) {
      updateControls(rangeControl, { from, to });
    });
  });

  document.querySelectorAll('[data-switch-control]').forEach(($switchControl) => {
    const { switchControl } = $switchControl.dataset;

    updateControls(switchControl, $switchControl.checked);
    $switchControl.addEventListener('change', () => updateControls(switchControl, $switchControl.checked));
  });
};