import * as Turbo from '@hotwired/turbo';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import '../scss/index.scss';

import { initApartment, apartmentClickHandler } from './modules/apartment';
import { initCookiebar, cookiebarClickHandler } from './modules/cookiebar';
import { initFadeTriggers } from './modules/fadeTrigger';
import { initForms, formClickHandler } from './modules/form';
import { initListing } from './modules/listing';
import { attachIntersectsEvents, detachIntersectsEvents } from './modules/intersect';
import { initMarquees } from './modules/marquee';
import { bannerModal, bannerCloseHanller } from './modules/bannerModal';
import { closeNav, naviconClickHandler, setNavItemActive } from './modules/nav';
import { initParallaxs } from './modules/parallax';
import { initRevealBtns } from './modules/revealBtn';
import { initRevealImages } from './modules/revealImage';
import { initRevealLogo } from './modules/revealLogo';
import { initRevealTexts } from './modules/revealText';
import { initRevealHeadings } from './modules/revealHeading';
import { scrollToClickHandler } from './modules/scrollto';
import { initShapes, shapesHrefClickHandler } from './modules/shapes';
import { initTogglers, togglerClickHandler } from './modules/toggler';
import { initVisuals } from './modules/visual';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('click', (e) => {
  apartmentClickHandler(e);
  cookiebarClickHandler(e);
  formClickHandler(e);
  naviconClickHandler(e);
  bannerCloseHanller(e);
  scrollToClickHandler(e);
  togglerClickHandler(e);
  shapesHrefClickHandler(e);
});

let trackMouse = false;

document.addEventListener('mousemove', ({ clientX, clientY }) => {
  if (!trackMouse) return;
  document.documentElement.style.setProperty('--mouseX', `${clientX}px`);
  document.documentElement.style.setProperty('--mouseY', `${clientY}px`);
});

// only fired once on initial load
window.addEventListener('load', () => {
  initRevealLogo();
});

// fired on every fetch after DOMContentLoaded
document.documentElement.addEventListener('turbo:load', (e) => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  attachIntersectsEvents();
  closeNav();
  bannerModal();
  setNavItemActive(e.detail.url);
  initApartment();
  initCookiebar();
  initFadeTriggers();
  initForms();
  initListing();
  initMarquees();
  initParallaxs();
  initVisuals();
  initRevealBtns();
  initRevealImages();
  initRevealHeadings();
  initRevealTexts();
  initShapes();
  initTogglers();
  trackMouse = !!document.querySelector('[data-shapes]');
  if (trackMouse) return;
  document.documentElement.style.removeProperty('--mouseX');
  document.documentElement.style.removeProperty('--mouseY');
});

// do not refetch the same page
document.documentElement.addEventListener('turbo:before-visit', (e) => {
  detachIntersectsEvents();
  if (e.detail.url === location.href) {
    e.preventDefault();
    closeNav();
  }
});

// prevent bfcache:
// https://web.dev/bfcache/
// https://stackoverflow.com/questions/7248111/how-to-prevent-content-being-displayed-from-back-forward-cache-in-firefox
// https://turbo.hotwired.dev/handbook/building#opting-out-of-caching
