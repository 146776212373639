export const fixBody = () => {
  document.documentElement.style.setProperty('--bodyPaddingRight', `${window.innerWidth - document.body.clientWidth}px`);
  document.body.style.overflow = 'hidden';
};

export const releaseBody = () => {
  document.body.style.overflow = '';
  document.documentElement.style.removeProperty('--bodyPaddingRight');
};

export const openNav = () => {
  document.querySelector('[data-nav]')?.classList.add('is-open');
  document.querySelector('[data-navicon]')?.classList.add('is-open');
  fixBody();
};

export const closeNav = () => {
  document.querySelector('[data-nav]')?.classList.remove('is-open');
  document.querySelector('[data-navicon]')?.classList.remove('is-open');
  releaseBody();
};

export const setNavItemActive = (url) => {
  document.querySelectorAll('a').forEach(($a) => {
    $a.classList[$a.href === url ? 'add' : 'remove']('is-active');
    $a.parentElement.classList[$a.href === url ? 'add' : 'remove']('is-active');
  });
};

export const naviconClickHandler = (e) => {
  const $navIcon = e.target.closest('[data-navicon]');

  if (!$navIcon) return;
  const isNavOpen = $navIcon.classList.contains('is-open');

  isNavOpen ? closeNav() : openNav();
};
