import { gsap } from "gsap";

export const initRevealLogo = () => {
  const $logo = document.querySelector('[data-reveal-logo]');

  gsap.to($logo, {
    y: 0,
    opacity: 1,
    duration: 0.8,
    ease: "expo.out",
    scrollTrigger: {
      trigger: $logo,
    },
  });
};