import Cookies from "js-cookie";

export const cookiebarClickHandler = (e) => {
  const $cookiebarAccept = e.target.closest("[data-cookiebar-accept]");
  if (!$cookiebarAccept) return;

  const $cookiebar = e.target.closest("[data-cookiebar]");
  const cookieName = $cookiebar.dataset.cookiebar;

  Cookies.set(cookieName, "true");
  $cookiebar.classList.remove("is-visible");

  const transitionStyle = getComputedStyle($cookiebar)
    .transitionDuration.split(",")
    .map((dur) => dur.replace("ms", ""))
    .map((dur) => dur.replace("s", "") * 1000)
    .map((dur) => Number(dur));
  const duration = Math.max(...transitionStyle);
  setTimeout(() => $cookiebar.remove(), duration);
};

export const initCookiebar = () => {
  const $cookiebar = document.querySelector("[data-cookiebar]");
  const cookieName = $cookiebar?.dataset?.cookiebar;

  if (!Cookies.get(cookieName) && $cookiebar) {
    $cookiebar.classList.add("is-visible");
  }
};

window.c = () => Cookies.remove("totoriai_cookiebar_set");
