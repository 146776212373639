import { gsap } from "gsap";

export const initParallax = ($el) => {
  gsap.to($el, {
    yPercent: +$el.dataset.parallax || 30,
    scrollTrigger: {
      trigger: $el,
      scrub: 1,
    },
  })
}

export const initParallaxs = () => Array
  .from(document.querySelectorAll('[data-parallax]'))
  .forEach(initParallax);