import { gsap } from "gsap";

// cache elements for click handler
// initial mode
let visualMode = "3d";

export const apartmentClickHandler = (e) => {
  const $apartment = e.target.closest("[data-apartment]");
  if (!$apartment) return;

  const $apartmentOpen = e.target.closest("[data-apartment-open]");
  const $apartmentClose = e.target.closest("[data-apartment-close]");
  const $apartmentNav = e.target.closest("[data-apartment-nav]");

  const $apartmentContact = document.querySelector("[data-apartment-contact]");
  const $apartmentInfo = document.querySelector("[data-apartment-info]");
  if ($apartmentOpen) {
    gsap.fromTo(
      $apartmentInfo,
      {
        scale: 1,
        opacity: 1,
      },
      {
        scale: 0.8,
        opacity: 0,
        duration: 0.6,
        ease: "ease.out",
      }
    );
    gsap.fromTo(
      $apartmentContact,
      {
        scale: 1.2,
        opacity: 0,
      },
      {
        delay: 0.4,
        scale: 1,
        opacity: 1,
        duration: 0.6,
        pointerEvents: "all",
        ease: "ease.out",
      }
    );
  }
  if ($apartmentClose) {
    gsap.fromTo(
      $apartmentContact,
      {
        scale: 1,
        opacity: 1,
      },
      {
        scale: 0.8,
        opacity: 0,
        duration: 0.6,
        pointerEvents: "none",
        ease: "ease.out",
      }
    );
    gsap.fromTo(
      $apartmentInfo,
      {
        scale: 1.2,
        opacity: 0,
      },
      {
        delay: 0.4,
        scale: 1,
        opacity: 1,
        duration: 0.6,
        ease: "ease.out",
      }
    );
  }
  if ($apartmentNav) {
    const $apartmentSwitch = document.querySelector("[data-apartment-switch]");
    const visualMode = $apartmentSwitch.checked ? "2d" : "3d";
    const direction = $apartmentNav.dataset.apartmentNav;
    const $apartmentVisual = document.querySelector(`[data-apartment-visual="${visualMode}"]`);
    const $$apartmentImages = Array.from($apartmentVisual.querySelectorAll("[data-apartment-image]"));
    const currentIndex = $$apartmentImages.findIndex(($image) => $image.classList.contains("is-active"));
    const $currentImage = $$apartmentImages[currentIndex];
    if (direction === "prev") {
      const targetIndex = currentIndex === 0 ? $$apartmentImages.length - 1 : currentIndex - 1;
      const $targetImage = $$apartmentImages[targetIndex];
      gsap.fromTo(
        $currentImage,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: 50,
          opacity: 0,
          duration: 0.3,
          ease: "ease.out",
          onComplete: () => $currentImage.classList.remove("is-active"),
        }
      );
      $targetImage.classList.add("is-active"),
        gsap.fromTo(
          $targetImage,
          {
            x: -50,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.3,
            ease: "ease.out",
          }
        );
    }
    if (direction === "next") {
      const targetIndex = currentIndex === $$apartmentImages.length - 1 ? 0 : currentIndex + 1;
      const $targetImage = $$apartmentImages[targetIndex];
      gsap.fromTo(
        $currentImage,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: 50,
          opacity: 0,
          duration: 0.3,
          ease: "ease.out",
          onComplete: () => $currentImage.classList.remove("is-active"),
        }
      );
      $targetImage.classList.add("is-active"),
        gsap.fromTo(
          $targetImage,
          {
            x: -50,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.3,
            ease: "ease.out",
          }
        );
    }
  }
};

export const initApartment = () => {
  const $apartmentSwitch = document.querySelector("[data-apartment-switch]");

  if (!$apartmentSwitch) return;

  const $$apartmentVisuals = Array.from(document.querySelectorAll("[data-apartment-visual]"));

  const toggleVisual = () => {
    const visualMode = $apartmentSwitch.checked ? "2d" : "3d";
    $$apartmentVisuals.forEach(($apartmentVisual) => {
      if ($apartmentVisual.dataset.apartmentVisual === visualMode) {
        $apartmentVisual.classList.add('is-active');
        gsap.fromTo(
          $apartmentVisual,
          {
            x: -50,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.3,
            ease: "ease.out",
          }
        );
        return;
      }
      gsap.fromTo(
        $apartmentVisual,
        {
          x: 0,
          opacity: 1,
        },
        {
          x: 50,
          opacity: 0,
          duration: 0.3,
          ease: "ease.out",
          onComplete: () => $apartmentVisual.classList.remove("is-active"),
        }
      );
    });
  };

  toggleVisual();

  $apartmentSwitch.addEventListener("change", toggleVisual);
  document.documentElement.style.setProperty("--bodyBackgroundColor", "#fff");
};
