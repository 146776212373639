import { gsap } from "gsap";

export const initVisual = ($el) => {
  console.log('initVisual')
  gsap.to($el, {
    yPercent: 40,
    scrollTrigger: {
      trigger: $el,
      start: "top bottom-=40vh",
      scrub: true
    },
  })
}

export const initVisuals = () => document.querySelectorAll('[data-visual]').forEach(initVisual);