import * as Turbo from '@hotwired/turbo';

export const STATUS_ENUM = ['vacant', 'reserved', 'sold']

export const shapesHrefClickHandler = (e) => {
  const $shapeHref = e.target.closest('[data-shape-href]');
  if (!$shapeHref) return;

  Turbo.visit($shapeHref.dataset.shapeHref);
};

export const initShape = ($shape, { $bubble, $bubbleId, $bubbleRow1, $bubbleRow2 }) => {
  const { shape, shapeRow1, shapeRow2, shapeStatus } = $shape.dataset;

  $shape.addEventListener('mouseenter', () => {
    $bubble.classList.add('is-active');
    if ($bubbleId) $bubbleId.innerText = shape;
    if ($bubbleRow1) $bubbleRow1.innerText = shapeRow1 || '';
    if ($bubbleRow2) $bubbleRow2.innerText = shapeRow2 || '';
    if (shapeStatus) {
      STATUS_ENUM.forEach((status) => $bubble.classList[status === shapeStatus ? 'add' : 'remove'](`is-${status}`));
    }
  });
  $shape.addEventListener('mouseleave', () => $bubble.classList.remove('is-active'));
}

export const initShapes = () => {
  const $dataShapes = document.querySelector('[data-shapes]');
  if (!$dataShapes) return;

  document.querySelectorAll('[data-shape]').forEach(($shape) => initShape($shape, {
    $bubble: document.querySelector('[data-shape-bubble]'),
    $bubbleId: document.querySelector('[data-shape-bubble-id]'),
    $bubbleRow1: document.querySelector('[data-shape-bubble-row1]'),
    $bubbleRow2: document.querySelector('[data-shape-bubble-row2]'),
    // $bubbleStatus: document.querySelector('[data-shape-buble-status]'),
  }));
};