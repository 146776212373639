import Cookies from "js-cookie";

export const bannerModal = () => {
    
    const htmlWrap = document.querySelector('html');
    const infoModal = document.getElementById('info-modal');
    if( infoModal && !Cookies.get('infoModal') ) {
        const intervalValue = infoModal.getAttribute('data-interval') ? infoModal.getAttribute('data-interval') : 24; 
        infoModal.classList.add('show');
        // htmlWrap.classList.add('unscroll');
        setTimeout(function(){
            const intervalConverted = new Date(new Date().getTime() + intervalValue * 60 * 60 * 1000);
            Cookies.set('infoModal', 1, { expires: intervalConverted });
        }, 2000);
    }
};

export const bannerCloseHanller = (e) => {
    const htmlWrap = document.querySelector('html');
    const infoModal = document.getElementById('info-modal');
    infoModal.classList.remove('show');
    // htmlWrap.classList.remove('unscroll');
};