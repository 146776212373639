import { gsap } from "gsap";

export const injectLetter = (letter, $wordSpan) => {
  const $letterSpan = document.createElement('span');

  $letterSpan.textContent = letter;
  $letterSpan.style.display = 'inline-flex';
  $wordSpan.appendChild($letterSpan);

  return $letterSpan;
}

export const revealHeading = ($el) => {
  const initialText = $el.innerText;
  if (!initialText) return;

  $el.textContent = '';
  gsap.set($el, { opacity: 1 });
  
  const words = initialText.split(' ');
  const $$letters = words.reduce((acc, word, wordIndex) => {
    const wordLetters = word.split('');
    const $wordSpan = document.createElement('span');

    $wordSpan.style.display = 'inline-flex';
    $wordSpan.style.overflow = 'hidden';

    const $$wordLetters = wordLetters.map((letter) => injectLetter(letter, $wordSpan));

    // if not last word, inject empty space
    if (wordIndex !== words.length - 1) {
      injectLetter('\u00A0', $wordSpan);
    }

    $el.appendChild($wordSpan);


    return [
      ...acc,
      ...$$wordLetters
    ];
  }, []);

  gsap.to($$letters, {
    y: 0,
    duration: 1,
    ease: "expo.out",
    stagger: 0.02,
    onComplete: () => {
      $el.removeAttribute('data-reveal-heading');
      $el.firstChild.style.overflow = null;
    },
    scrollTrigger: {
      trigger: $$letters,
    },
  });
};

export const initRevealHeadings = () => document.querySelectorAll('[data-reveal-heading]').forEach(revealHeading);